/// AUTH ACTIONS
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_SUCCEED = 'LOGIN_SUCCEED';

// NOTIFICATION
export const ADD_NEW_NOTIFICATION_FAIL = "ADD_NEW_NOTIFICATION_FAIL";
export const ADD_NEW_NOTIFICATION_SUCCESS = "ADD_NEW_NOTIFICATION_SUCCESS";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";

// STAFF
export const GET_STAFF_LIST = "GET_STAFF_LIST";

//SIGN UP USER REQUEST
export const GET_USER_REQUEST_LIST = "GET_USER_REQUEST_LIST";
export const GET_USER_REQUEST_DETAILS = "GET_USER_REQUEST_DETAILS";

//GET MASTERS LIST
export const GET_PLAN_LIST = "GET_PLAN_LIST";
export const GET_ROLE_LIST = "GET_ROLE_LIST";
export const GET_LICENCE_LIST = "GET_LICENCE_LIST";
export const GET_TOKEN_LIST = "GET_TOKEN_LIST";
export const GET_USER_RESEND_REQUEST = "GET_USER_RESEND_REQUEST";
export const GET_MEMBER_LIST = "GET_MEMBER_LIST";
export const GET_PROJECT_LIST = "GET_PROJECT_LIST";
export const GET_SEARCH_PROJECT_LIST = "GET_SEARCH_PROJECT_LIST";
export const GET_COMPANY_LICENCE_LIST = "GET_COMPANY_LICENCE_LIST";
export const GET_PROJECT_TOKEN_PRICE = "GET_PROJECT_TOKEN_PRICE";
export const GET_PROJECT_TOKEN_REQUEST = "GET_PROJECT_TOKEN_REQUEST";
export const GET_PROJECT_TOKEN_LIST = "GET_PROJECT_TOKEN_LIST";
export const GET_MATERIAL_MASTER_LIST = "GET_MATERIAL_MASTER_LIST";
export const GET_MATERIAL_DETAILS = "GET_MATERIAL_DETAILS";
export const GET_COLOR_LIST = "GET_COLOR_LIST";
export const GET_DEFAULT_COLOR_LIST = "GET_DEFAULT_COLOR_LIST";
export const GET_NATURE_LIST = "GET_NATURE_LIST";
export const GET_GEOLOGY_LIST = "GET_GEOLOGY_LIST";
export const GET_ORGANOLEPTIC_LIST = "GET_ORGANOLEPTIC_LIST";
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const GET_LITHOLOGY_LIST = "GET_LITHOLOGY_LIST";
export const GET_DEFAULT_LITHOLOGY_LIST = "GET_DEFAULT_LITHOLOGY_LIST";
export const GET_MOISTURE_LIST = "GET_MOISTURE_LIST";
export const GET_ANOMALY_LIST = "GET_ANOMALY_LIST";
export const GET_REASON_LIST = "GET_REASON_LIST";
export const GET_PROBING_METHOD_LIST = "GET_PROBING_METHOD_LIST";
export const GET_PROBING_METHOD_DETAILS = "GET_PROBING_METHOD_DETAILS";
export const GET_OTHER_MATERIAL_DETAILS = "GET_OTHER_MATERIAL_DETAILS";
export const GET_TEST_TYPE_LIST = "GET_TEST_TYPE_LIST";
export const GET_SUPPORT_INVITATION_LIST = "GET_SUPPORT_INVITATION_LIST";
export const GET_TEST_TYPE_DETAILS = "GET_TEST_TYPE_DETAILS";
export const GET_DESPOSAL_LIST = "GET_DESPOSAL_LIST";
export const GET_STANDARD_DESPOSAL_LIST = "GET_STANDARD_DESPOSAL_LIST";
export const GET_ALL_DESPOSAL_RULELIST = "GET_ALL_DESPOSAL_RULELIST";
export const GET_DESPOSAL_DETAILS = "GET_DESPOSAL_DETAILS";
export const GET_TEST_RULE_LIST = "GET_TEST_RULE_LIST";
export const GET_TEST_LIST = "GET_TEST_LIST";
export const GET_TEST_DETAILS = "GET_TEST_DETAILS";
export const GET_PROJECT_DETAILS = "GET_PROJECT_DETAILS";
export const GET_INVITE_USER_LIST = "GET_INVITE_USER_LIST";
export const ALL_MESSAGES = "ALL_MESSAGES";
export const REMOVE_ALL_MESSAGES = "REMOVE_ALL_MESSAGES";
export const COMPANY_PROJECT_LIST_LOGIN = "COMPANY_PROJECT_LIST_LOGIN";
export const COMPANY_PROJECT_LIST_FOR_SWITCH_COMPANY = "COMPANY_PROJECT_LIST_FOR_SWITCH_COMPANY";
export const COMPANY_PROJECT_LIST_FOR_NOTFOUND = "COMPANY_PROJECT_LIST_FOR_NOTFOUND";
export const GET_PROJECT_LICENCE_LIST = "GET_PROJECT_LICENCE_LIST";
export const GET_REQUEST_MEMBER_LIST = "GET_REQUEST_MEMBER_LIST";
export const GET_LICECNCE_REQUST_LIST = "GET_LICECNCE_REQUST_LIST";
export const GET_LICECNCE_RENEW_REQUST_LIST = "GET_LICECNCE_RENEW_REQUST_LIST";
export const GET_LICECNCE_REQUST_DETAIL = "GET_LICECNCE_REQUST_DETAIL";
export const GET_TOKEN_REQUST_DETAIL = "GET_TOKEN_REQUST_DETAIL";
export const GET_PLAN_REQUST_DETAIL = "GET_PLAN_REQUST_DETAIL";
export const GET_ACTIVE_PROJECT_LIST = "GET_ACTIVE_PROJECT_LIST";
export const GET_MEMBER_DETAILS = "GET_MEMBER_DETAILS";
export const GET_SEARCH_TEST_FAMILY = "GET_SEARCH_TEST_FAMILY";
export const GET_USERS_WITH_PLAN_LIST = "GET_USERS_WITH_PLAN_LIST";
export const GET_COMPANY_WISE_PLAN_HISTORY = "GET_COMPANY_WISE_PLAN_HISTORY";
export const GET_COMPANY_WISE_LICENCE_HISTORY = "GET_COMPANY_WISE_LICENCE_HISTORY";
export const GET_SUPERADMIN_DASHBOARD_DATA = "GET_SUPERADMIN_DASHBOARD_DATA";
export const GET_CLIENT_DASHBOARD_DATA = "GET_CLIENT_DASHBOARD_DATA";
export const GET_STATUS_WISE_PROJECT_LIST = "GET_STATUS_WISE_PROJECT_LIST";
export const GET_SYSTEM_LOG = "GET_SYSTEM_LOG";
export const GET_ALL_USERS_LIST_DASHBOARD = "GET_ALL_USERS_LIST_DASHBOARD";
export const GET_ALL_MEMBERS_PROJECT_LIST = "GET_ALL_MEMBERS_PROJECT_LIST";
export const GET_AVAILABLE_LICENCE_LIST = "GET_AVAILABLE_LICENCE_LIST";
export const GET_MEMBER_PROJECT_DETAILS = "GET_MEMBER_PROJECT_DETAILS";
export const GET_CAMPAIGN_TEST_ANALYSIS_DATA = "GET_CAMPAIGN_TEST_ANALYSIS_DATA";
export const GET_MULTIPLE_CAMPAIGN_TEST_ANALYSIS_DATA = "GET_MULTIPLE_CAMPAIGN_TEST_ANALYSIS_DATA";
export const GET_OBSERVATION_LIST = "GET_OBSERVATION_LIST";
export const GET_PATTERN_LIST = "GET_PATTERN_LIST";
export const GET_PROBING_LIST_TABLE_CSV = "GET_PROBING_LIST_TABLE_CSV";
export const GET_STANDARD_DESPOSAL_RULE_LIST = "GET_STANDARD_DESPOSAL_RULE_LIST";


// CAMPAIGN
export const GET_CAMPAIGN_LIST = "GET_CAMPAIGN_LIST";
export const GET_CAMPAIGN_DETAILS = "GET_CAMPAIGN_DETAILS";
export const GET_NOT_PROJECT_MEMBER_LIST = "GET_NOT_PROJECT_MEMBER_LIST";
export const GET_CLOSED_CAMPAIGN_LIST = "GET_CLOSED_CAMPAIGN_LIST";
export const GET_APPROVED_CAMPAIGN_LIST = "GET_APPROVED_CAMPAIGN_LIST";
export const GET_CAMPAIGN_LIST_DASHBOARD = "GET_CAMPAIGN_LIST_DASHBOARD";


// MY DOCUMENT
export const GET_USER_DOCUMENT_LIST = "GET_USER_DOCUMENT_LIST";
export const USER_DOCUMENT_LIST = "USER_DOCUMENT_LIST"
export const GET_SHARE_DOCUMENT_LIST = "GET_SHARE_DOCUMENT_LIST"
export const GET_SHARE_DOCUMENT_LIST_TO_OTHERS = "GET_SHARE_DOCUMENT_LIST_TO_OTHERS"
export const GET_PROJECT_DOCUMENT_LIST = "GET_PROJECT_DOCUMENT_LIST"

//Get User Document Info
export const GET_USER_DOCUMENT_INFO = "GET_USER_DOCUMENT_INFO"

//Get comapny Document 
export const GET_COMP_DOCUMENT_LIST = "GET_COMP_DOCUMENT_LIST"

// PROBING
export const GET_CREATE_PROBING_LIST = "GET_CREATE_PROBING_LIST"
export const GET_PROBING_LIST = "GET_PROBING_LIST"
export const GET_PROBING_DETAIL = "GET_PROBING_DETAIL"
export const GET_PROBING_TODO_LOG_LIST = "GET_PROBING_TODO_LOG_LIST"
export const GET_CAMPAIGN_TODO_LOG_LIST = "GET_CAMPAIGN_TODO_LOG_LIST"

// CONTENT MANAGMENT

export const GET_EMAIL_TEMPLATE_LIST = "GET_EMAIL_TEMPLATE_LIST"
export const GET_EMAIL_TEMPLATE_DETAILS = "GET_EMAIL_TEMPLATE_DETAILS"
export const GET_USERS_LIST_TO_SEND_NOTIFICATION = "GET_USERS_LIST_TO_SEND_NOTIFICATION"
export const GET_NOTIFICATION_TEMPLATE_LIST = "GET_NOTIFICATION_TEMPLATE_LIST"
export const GET_NOTIFICATION_TEMPLATE_DETAILS = "GET_NOTIFICATION_TEMPLATE_DETAILS"
export const GET_EMAIL_SETTINGS_LIST = "GET_EMAIL_SETTINGS_LIST";
export const GET_NOTIFICATION_SETTINGS_LIST = "GET_NOTIFICATION_SETTINGS_LIST";
export const GET_SEND_NOTIFICATION_FILTERS = "GET_SEND_NOTIFICATION_FILTERS";
export const GET_NOTIFICATION_FILTERS_WISE_USER = "GET_NOTIFICATION_FILTERS_WISE_USER";


// SET VIEW PROBING URL

export const SET_CURRENT_URL = "SET_CURRENT_URL"

// NOTIFICATION LIST

export const GET_USER_NOTIFICATION_LIST = "GET_USER_NOTIFICATION_LIST"
export const GET_USER_NOTIFICATION_UNREAD_COUNT = "GET_USER_NOTIFICATION_UNREAD_COUNT"
export const CURRENT_NOTIFICATION_REDIRECT_PAGE_URL = "CURRENT_NOTIFICATION_REDIRECT_PAGE_URL";

// INVOICE LIST

export const GET_INVOICE_LIST = "GET_INVOICE_LIST"
export const GET_COMPANY_WISE_INVOICE_LIST = "GET_COMPANY_WISE_INVOICE_LIST"

// GET_PLAN_RENEWAL_LIST

export const GET_PLAN_RENEWAL_LIST = "GET_PLAN_RENEWAL_LIST"
export const GET_LICENCE_RENEWAL_LIST = "GET_LICENCE_RENEWAL_LIST"

// Data visulization
export const GET_CAMPAIGNS_FOR_MAP = "GET_CAMPAIGNS_FOR_MAP";
export const GET_MAPVISUALIZE_DATA_LIST = "GET_MAPVISUALIZE_DATA_LIST";
export const GET_TEST_ANALYSIS_PROBING_DATA = "GET_TEST_ANALYSIS_PROBING_DATA";
export const GET_MULTIPLE_POLLUTANT_WISE_TEST_ANALYSIS_DATA = "GET_MULTIPLE_POLLUTANT_WISE_TEST_ANALYSIS_DATA";
export const GET_TEST_ANALYSIS_DATA_PROJECT_WISE = "GET_TEST_ANALYSIS_DATA_PROJECT_WISE";
export const GET_TEST_ANALYSIS_MULTIPLE_PROBING_DATA = "GET_TEST_ANALYSIS_MULTIPLE_PROBING_DATA";
export const GET_MAP_EXPORT_DATA_LIST = "GET_MAP_EXPORT_DATA_LIST";

// GET_LANGUAGE_LIST
export const GET_LANGUAGE_LABEL_LIST = "GET_LANGUAGE_LABEL_LIST";
export const GET_LANGUAGE_NEW_LABEL_LIST = "GET_LANGUAGE_NEW_LABEL_LIST";

export const GET_LANGUAGE_LABEL_CHANGE_LIST = "GET_LANGUAGE_LABEL_CHANGE_LIST";

export const GET_LANGUAGE_CODE_LABEL_LIST = "GET_LANGUAGE_CODE_LABEL_LIST";
export const GET_PAGE_WISE_LANGUAGE_LIST = "GET_PAGE_WISE_LANGUAGE_LIST";

export const SAVE_MAPVISUALIZE_DATA = "SAVE_MAPVISUALIZE_DATA";
export const GET_SELECTED_MAPVISUALIZE_DATA = "GET_SELECTED_MAPVISUALIZE_DATA";
export const UPDATED_MAPVISUALIZE_DATA = "UPDATED_MAPVISUALIZE_DATA";
export const GET_MAP_IMAGE_GALLERY = "GET_MAP_IMAGE_GALLERY";


// Excel Upload
export const UPLOAD_EXCEL = "UPLOAD_EXCEL";
export const SAVE_EXCEL_DATA = "SAVE_EXCEL_DATA";
export const GET_EXCEL_DATA = "GET_EXCEL_DATA";
export const GET_SAMPLE_DATA_OF_POLLUTANT = "GET_SAMPLE_DATA_OF_POLLUTANT";
export const GET_SAMPLE_DATA_OF_POLLUTANT_FOR_LABEL_OF_DV = "GET_SAMPLE_DATA_OF_POLLUTANT_FOR_LABEL_OF_DV";
export const GET_SELECTED_EXCEL_DATA = "GET_SELECTED_EXCEL_DATA";
export const GET_PROBINGS_BY_LENGTH = "GET_PROBINGS_BY_LENGTH";
export const GET_EXCEL_SETTINGS = "GET_EXCEL_SETTINGS";
export const GET_EXCEPTION_SETTINGS = "GET_EXCEPTION_SETTINGS";
export const GET_MATCHED_TESTFAMYLIES = "GET_MATCHED_TESTFAMYLIES";

export const GET_ANALYSIS = "GET_ANALYSIS";
export const SAVE_ANALYSIS = "SAVE_ANALYSIS";
export const GET_PROBING_REPORT = "GET_PROBING_REPORT";
export const GENERATE_DIRECT_ANALYSIS_REPORT = "GENERATE_DIRECT_ANALYSIS_REPORT";
export const GENERATE_DIRECT_PROBING_REPORT = "GENERATE_DIRECT_PROBING_REPORT";

// Analysis

export const GET_3D_PROBING_ANALYSIS_GRAPH = "GET_3D_PROBING_ANALYSIS_GRAPH";
export const GET_TEST_WISE_RULE_LIST = "GET_TEST_WISE_RULE_LIST";
export const GET_3D_TERRAIN_ANALYSIS_GRAPH = "GET_3D_TERRAIN_ANALYSIS_GRAPH";
export const GET_3D_POLLUTANT_ANALYSIS_GRAPH = "GET_3D_POLLUTANT_ANALYSIS_GRAPH";
export const GET_POLLUTION_LIST = "GET_POLLUTION_LIST";

// Probing Excel Upload
export const UPLOAD_PROBING_EXCEL = "UPLOAD_PROBING_EXCEL";
export const SAVE_IMPORT_PROBING_EXCEL = "SAVE_IMPORT_PROBING_EXCEL";
export const GET_PROBING_EXCEL_DATA = "GET_PROBING_EXCEL_DATA";

export const GET_MAPWISE_PROBING_LIST_REPORT = "GET_MAPWISE_PROBING_LIST_REPORT";

//MENU_TOGGLE
export const MENU_TOGGLE = "MENU_TOGGLE";

//Sample-Disposal Settings
export const GET_FORCED_DISPOSAL_TO_SAMPLE = "GET_FORCED_DISPOSAL_TO_SAMPLE";

//Color picker data
export const  GET_ALL_COLOR_PICKER_DATA = "GET_ALL_COLOR_PICKER_DATA"

